import { httpClient, internalHttpClient } from '@/libs/axios';
import { getAppCheckToken } from '@/libs/firebase';
import { SignInPayload, SignupPayload } from '@/type/Auth';

interface InternalTokenPayload {
  client_id: string | undefined;
  secret_token: string | undefined;
}

export const getInternalToken = async (payload: InternalTokenPayload): Promise<any> => {
  try {
    const response = await internalHttpClient.post('/auth/', payload);
    const data = await response.data;
    return data;
  } catch (error) {}
};

export const signUp = async (payload: SignupPayload): Promise<any> => {
  try {
    const response = await httpClient.post('/users', payload);
    const data = await response.data;
    return data;
  } catch (error: any) {
    throw error.error;
  }
};

export const signIn = async (payload: SignInPayload): Promise<any> => {
  try {
    const token = (await getAppCheckToken()) || '';
    const response = await httpClient.post('/users/login/', payload, {
      headers: {
        'X-Firebase-AppCheck': token,
      },
    });
    const data = await response.data;
    return {
      userId: data.local_id,
      accessToken: data.id_token,
      refreshToken: data.refresh_token,
    };
  } catch (error: any) {
    throw error.error;
  }
};

export const refreshTokenApi = async (refreshToken: string | undefined): Promise<any> => {
  try {
    const token = (await getAppCheckToken()) || '';
    const response = await httpClient.post('/users/refresh/', { refresh_token: refreshToken },{
      headers: {
        'X-Firebase-AppCheck': token,
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    window.location.href = '/register';
  }
};
