import { getCustomerInfoCP, updatePrivacy } from '@/api/user';
import Logo from '@/components/common/Logo';
import VerificationPhoneCodeModal from '@/components/modal/VerificationPhoneCode';
import { sendVerificationCode, signInWithCustomTokens, signOutUser } from '@/libs/firebase';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import authReducer from '@/redux/reducers/authReducer';
import errorReducer from '@/redux/reducers/errorReducer';
import userReducer from '@/redux/reducers/userReducer';
import { Content, Divider, StyledButton, SubTitle, Title, WelcomeText } from '@/styles/common';
import { clearSavedToken, saveToken } from '@/utils/auth';
import { ERROR_CODES, UserType } from '@/utils/constant';
import { endUrlForStep } from '@/utils/helper';
import { ModalRenderer } from '@/utils/modalUtils';
import { Skeleton } from '@mui/material';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CheckboxTerm from '../Register/CheckboxTerm';
import { CheckboxTermWrapper, ErrorMessage, Homepage } from './styles';
interface IntroPageProps {
  userType: number;
  name?: string;
  isLoadingPartner?: boolean;
}

const IntroPage: React.FC<IntroPageProps> = ({ userType, name = '', isLoadingPartner = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // const reCapchaContainerElement = useRef(null);
  const currentStep = useAppSelector((state: any) => state.sidebar.currentStep);
  const customToken = useAppSelector((state: any) => get(state, 'user.userInfo.customToken'));
  const partnerCP = useAppSelector((state: any) => state.user.partner);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [disableBtn, setDisableBtn] = useState<boolean>(false);
  const [checkTerm, setCheckTerm] = React.useState(false);

  const welcomeText = t('HOMEPAGE_WELCOME_TEXT', { name: name })?.split('\n');

  const content = [
    {
      welcomeText: welcomeText[UserType.ADDITIONAL_DRIVER],
      title: t('HOMEPAGE_TITLE_AD'),
      subTitle: (
        <Trans
          i18nKey="HOMEPAGE_SUBTITLE_AD"
          values={{ name: name }}
          components={{
            name: <span className="fs-mask" />,
          }}
        />
      ),
    },
    {
      welcomeText: welcomeText[UserType.CONCIERGE_PORTAL],
      title: (
        <Trans
          i18nKey="HOMEPAGE_TITLE_CP"
          values={{ name: name }}
          components={{
            name: <span className="fs-mask" />,
          }}
        />
      ),
      subTitle: t('HOMEPAGE_SUBTITLE_CP'),
    },
    {
      welcomeText: welcomeText[UserType.RENTAL_CARS],
      title: t('HOMEPAGE_TITLE_RC'),
      subTitle: t('HOMEPAGE_SUBTITLE_RC'),
    },
  ];

  useEffect(() => {
    clearSavedToken();
    signOutUser();
    dispatch(authReducer.actions.clearAuth());
    dispatch(userReducer.actions.clearUserInfo());
    dispatch({ type: 'RESET_STEP' });
    if (userType === UserType.CONCIERGE_PORTAL) {
      setDisableBtn(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userType === UserType.CONCIERGE_PORTAL) {
      if (!checkTerm) {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    }
  }, [checkTerm, userType]);

  // const clearRecapchaContent = () => {
  //   if (reCapchaContainerElement.current) {
  //     //@ts-ignore
  //     reCapchaContainerElement.current.innerHTML = '<div id="recapcha-container"></div>';
  //   }
  // };

  const onHandleSendVerificationCode = async (phoneNumber: string, isVerified: boolean) => {
    try {
      setErrorMessage('');
      // clearRecapchaContent();
      dispatch({ type: 'SHOW_LOADING' });
      const isSendVerificationSuccess = await sendVerificationCode(phoneNumber);
      if (isSendVerificationSuccess) {
        ModalRenderer.open({
          component: VerificationPhoneCodeModal,
          theme: 'white',
          inMobile: 'bottom',
          props: {
            phoneNumber: phoneNumber,
            isVerified: isVerified,
          },
        });
      }
    } catch (error) {
      if (error === ERROR_CODES.INVALID_PHONE_NUMBER || error === ERROR_CODES.EXISTING_PHONE_NUMBER) {
        dispatch(errorReducer.actions.setError({ title: t('ERROR_PHONE_TITLE'), content: t('ERROR_PHONE_CONTENT') }));
        navigate('/error');
        return;
      }
      if (error === ERROR_CODES.TOO_MANY_REQUESTS || error === ERROR_CODES.TOO_MANY_REQUEST_CODE) {
        setErrorMessage(t('ERROR_MESSAGE_TOO_MANY_REQUESTS'));
        return;
      }
      if (error === ERROR_CODES.TOO_MANY_ATTEMPS_CODE) {
        setErrorMessage(t('ERROR_MESSAGE_TOO_MANY_ATTEMPS_CODE'));
        return;
      }
      setErrorMessage(t('ERROR_MESSAGE_SOMETHING_WENT_WRONG'));
    } finally {
      dispatch({ type: 'HIDE_LOADING' });
    }
  };

  const renderWelcomeText = () => {
    if (userType === UserType.CONCIERGE_PORTAL) {
      return t(`PARTNER_${partnerCP?.toUpperCase()}`);
    }
    if (userType === UserType.ADDITIONAL_DRIVER) return content[userType].welcomeText;
  };

  const handleStartedBtn = async () => {
    setDisableBtn(true);
    dispatch(userReducer.actions.updateUserType(userType));
    if (userType === UserType.CONCIERGE_PORTAL) {
      //handle for CP
      dispatch({ type: 'SHOW_LOADING' });
      await onHandleCPUser();
      dispatch({ type: 'HIDE_LOADING' });
    } else {
      navigate(endUrlForStep(userType, currentStep));
    }
  };

  const onHandleCPUser = async () => {
    if (checkTerm && customToken) {
      try {
        const auth = await signInWithCustomTokens(customToken);
        // save accessToken to localStorage
        saveToken(auth?.accessToken!);
        // save refreshToken to cookie
        Cookies.set('refreshToken', auth?.refreshToken!);
        //update privacy
        await updatePrivacy(auth?.userId, { privacy: checkTerm, checkin_url_used: false });
        // get phone number
        const userInfo = await getCustomerInfoCP(auth?.userId);
        //need to add creditcards and flag to open add card
        dispatch(userReducer.actions.setUserInfo({ ...userInfo, customToken: customToken }));
        dispatch(userReducer.actions.updateIsAddPaymentMethod(true));
        const phoneNumber = '+' + userInfo?.phone_number;
        let isVerified = false;
        if (auth?.phoneNumber) isVerified = true;
        await onHandleSendVerificationCode(phoneNumber, isVerified);
        setDisableBtn(false);
      } catch (error: any) {
        if (error === 'PrivacyNoPolicy') {
          return;
        }
        dispatch(errorReducer.actions.setError({ title: t('ERROR_LINK_TITLE'), content: t('ERROR_LINK_CONTENT') }));
        navigate('/error');
      }
    }
  };

  return (
    <Homepage className="fs-unmask">
      <Helmet>
        <meta name="theme-color" content="#dddcd8" />
      </Helmet>
      <Content>
        <Logo />
        <Divider />
        {isLoadingPartner ? <Skeleton variant="text" /> : <WelcomeText>{renderWelcomeText()}</WelcomeText>}
        <Title variant="title" color="primary">
          {content[userType].title}
        </Title>
        <SubTitle variant="content" color="secondary">
          {content[userType].subTitle}
        </SubTitle>
        {userType === UserType.CONCIERGE_PORTAL && (
          <CheckboxTermWrapper>
            <CheckboxTerm
              onClick={() => {
                setCheckTerm((prev) => !prev);
              }}
              checked={checkTerm}
              mode={'light'}
              text={
                <Trans
                  i18nKey="ACCEPT_TERM_AND_POLICY"
                  components={{
                    link1: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href="https://theout.zendesk.com/hc/en-gb/articles/9948882884125-Terms-and-Conditions"
                        target="blank"
                      />
                    ),
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    link2: (
                      <a
                        href="https://theout.zendesk.com/hc/en-gb/articles/9949036554141-Privacy-Policy"
                        target="blank"
                      />
                    ),
                  }}
                />
              }
              testId="test_policy"
            />
          </CheckboxTermWrapper>
        )}
        <StyledButton
          variant="contained"
          color={'dark'}
          onClick={handleStartedBtn}
          disabled={!!errorMessage || disableBtn}
        >
          {t('GET_STARTED')}
        </StyledButton>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </Content>
      {/* <RecapchaContainer ref={reCapchaContainerElement}>
        <div id="recapcha-container" />
      </RecapchaContainer> */}
    </Homepage>
  );
};
export default IntroPage;
