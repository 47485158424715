//@ts-nocheck
import { signIn, signUp } from '@/api/auth';
import { getUserInfo } from '@/api/user';
import { Button, StyledForm, Text } from '@/components/common';
import { DatePickerField, FormMarginDense, FormWrapper, PasswordField, TextField } from '@/components/form';
import VerificationPhoneCodeModal from '@/components/modal/VerificationPhoneCode';
import { ErrorMessage } from '@/components/modal/VerificationPhoneCode/styled';
import CheckPassword from '@/components/page/Register/CheckPassword';
import CheckboxTerm from '@/components/page/Register/CheckboxTerm';
import { firebaseLogin, getUserFirebase, sendVerificationCode } from '@/libs/firebase';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import userReducer from '@/redux/reducers/userReducer';
import { saveToken } from '@/utils/auth';
import { ERROR_CODES, UserType } from '@/utils/constant';
import { getUserTypeNumber } from '@/utils/helper';
import { ModalRenderer } from '@/utils/modalUtils';
import {
  BirthdateSchemaRegister,
  EmailSchema,
  FirstNameSchemaRegister,
  LastNameSchemaRegister,
  PasswordPolicySchema,
} from '@/utils/validation';
import { Formik } from 'formik';
import Cookies from 'js-cookie';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup.object().shape({
  email: EmailSchema,
  password: PasswordPolicySchema,
  first_name: FirstNameSchemaRegister,
  last_name: LastNameSchemaRegister,
  birth_date: BirthdateSchemaRegister,
});

const RegisterPage: React.FC<any> = () => {
  // const reCapchaContainerElement = useRef(null);
  const [checkMarketing, setCheckMarketing] = React.useState(false);
  const [checkTerm, setCheckTerm] = React.useState(false);
  const [errorLogin, setErrorLogin] = React.useState<string>('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formikRef = useRef();
  const userType = useAppSelector((state: any) => state.user.userType);
  const orderId = useAppSelector((state: any) => state.user.orderId);

  useEffect(() => {
    dispatch({ type: 'HIDE_LOADING' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = async (userId: string) => {
    try {
      const userInfo = await getUserInfo(userId);
      if (userInfo) {
        dispatch(userReducer.actions.setUserInfo(userInfo));
        return userInfo;
      }
    } catch (error) {
      formikRef.current.setFieldError('email', t('ERROR_MESSAGE_VALID_CREDENTIAL'));
    }
  };

  const onHandleSendVerificationCode = async (phoneNumber: string) => {
    try {
      dispatch({ type: 'SHOW_LOADING' });
      const isSendVerificationSuccess = await sendVerificationCode(phoneNumber);
      dispatch({ type: 'HIDE_LOADING' });
      if (isSendVerificationSuccess) {
        ModalRenderer.open({
          component: VerificationPhoneCodeModal,
          theme: 'white',
          inMobile: 'bottom',
          props: {
            phoneNumber: phoneNumber,
            isVerified: true,
          },
        });
      }
    } catch (error) {
      if (error === ERROR_CODES.INVALID_PHONE_NUMBER || error === ERROR_CODES.EXISTING_PHONE_NUMBER) {
        setErrorLogin(t('ERROR_MESSAGE_INVALID_PHONE_NUMBER'));
        return;
      }
      if (error === ERROR_CODES.TOO_MANY_REQUESTS || error === ERROR_CODES.TOO_MANY_REQUEST_CODE) {
        setErrorLogin(t('ERROR_MESSAGE_TOO_MANY_REQUESTS'));
        return;
      }
      if (error === ERROR_CODES.TOO_MANY_ATTEMPS_CODE) {
        setErrorLogin(t('ERROR_MESSAGE_TOO_MANY_ATTEMPS_CODE'));
        return;
      }
      setErrorLogin(t('ERROR_MESSAGE_SOMETHING_WENT_WRONG'));
    }
  };

  const handleAfterAuthSuccess = async (userId: string, accessToken: string, refreshToken: string) => {
    // save accessToken to localStorage
    saveToken(accessToken);
    // save refreshToken to cookie
    Cookies.set('refreshToken', refreshToken);
    await getUser(userId);
    const userInfo = getUserFirebase();
    if (userInfo?.phoneNumber) {
      const phoneNumber = userInfo?.phoneNumber;
      await onHandleSendVerificationCode(phoneNumber);
    } else {
      dispatch({ type: 'UPDATE_STATUS', payload: { create_account_passed: true } });
      dispatch({ type: 'NEXT_STEP' });
      dispatch({ type: 'SHOW_LOADING' });
      navigate('/verification-phone');
    }
  };

  const onLogin = async (email: string, password: string) => {
    try {
      await firebaseLogin(email, password);
      const authInfo = await signIn({ email, password });
      if (authInfo?.userId && authInfo?.accessToken && authInfo?.refreshToken) {
        await handleAfterAuthSuccess(authInfo?.userId, authInfo.accessToken, authInfo?.refreshToken);
      }
    } catch (error) {
      setErrorLogin(t('ERROR_MESSAGE_VALID_CREDENTIAL'));
    }
  };

  const onSubmit = async (values: any) => {
    // clearRecapchaContent();
    let payload = {
      email: values?.email,
      password: values?.password,

      notifications: {
        terms: checkTerm,
        marketing: checkMarketing,
      },
      licence: {
        first_name: values?.first_name,
        birthdate: moment(values?.birth_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        last_name: values?.last_name,
      },
      source: getUserTypeNumber(userType),
    };
    if (userType === UserType.ADDITIONAL_DRIVER) {
      payload = { ...payload, order_id: orderId };
    }
    try {
      dispatch({ type: 'SHOW_LOADING' });
      const userInfo = await signUp(payload);
      if (!!userInfo) {
        const authInfo = await firebaseLogin(values.email, values.password);
        if (authInfo?.userId && authInfo?.accessToken && authInfo?.refreshToken) {
          await handleAfterAuthSuccess(authInfo?.userId, authInfo.accessToken, authInfo?.refreshToken);
        }
      }
    } catch (error: any) {
      //check login and popup verification phone
      if (error === ERROR_CODES.INVALID_EMAIL || error === ERROR_CODES.EXISTING_EMAIL) {
        await onLogin(values.email, values.password);
        return;
      }
      if (
        error === ERROR_CODES.USER_NOT_FOUND ||
        error === ERROR_CODES.INVALID_PASSWORD ||
        error === ERROR_CODES.USER_DISABLE ||
        error === ERROR_CODES.USER_DISABLED
      ) {
        formikRef.current.setFieldError('email', t('ERROR_MESSAGE_VALID_CREDENTIAL'));
        return;
      }
      if (error === ERROR_CODES.INVALID_PASSWORD) {
        setErrorLogin(t('ERROR_MESSAGE_INVALID_PASSWORD'));
        return;
      }
      if (error === ERROR_CODES.PRIVACY_NO_POLICY) {
        setErrorLogin(t('ERROR_MESSAGE_INVALID_POLICY'));
        return;
      }
      setErrorLogin(t('ERROR_MESSAGE_SOMETHING_WENT_WRONG'));
    } finally {
      dispatch({ type: 'HIDE_LOADING' });
    }
  };
  return (
    <FormWrapper>
      <Text color="darkPrimary" variant="title">
        {t('CREATE_AN_ACCOUNT')}
      </Text>
      <Text color="darkSecondary" variant="content">
        {t('AD_CREATE_AN_ACCOUNT_SUBTITLE')}
      </Text>
      <Formik
        initialValues={{
          email: '',
          password: '',
          first_name: '',
          last_name: '',
          birth_date: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ errors, values, isValid }) => (
          <StyledForm>
            <div>
              {/* Email */}
              <FormMarginDense>
                <TextField
                  type="email"
                  name="email"
                  config={{
                    label: t('EMAIL_ADDRESS'),
                    onClick: () => setErrorLogin(null),
                    InputProps: {
                      'data-testid': 'test_email',
                    },
                  }}
                />
              </FormMarginDense>
              {/* Password */}
              <FormMarginDense>
                <PasswordField
                  name="password"
                  config={{
                    label: t('PASSWORD'),
                    onClick: () => setErrorLogin(null),
                    InputProps: {
                      'data-testid': 'test_password',
                    },
                  }}
                />
              </FormMarginDense>
              {/* Check password */}
              <FormMarginDense value="16">
                {/* @ts-ignore */}
                <CheckPassword newPassword={values?.password} />
              </FormMarginDense>
              {errorLogin ? <ErrorMessage>{errorLogin}</ErrorMessage> : null}
              <FormMarginDense value="32">
                <TextField
                  type="text"
                  name="first_name"
                  config={{
                    label: t('FIRST_NAME'),
                    maxLength: 255,
                    InputProps: {
                      'data-testid': 'test_first_name',
                    },
                  }}
                />
              </FormMarginDense>
              <FormMarginDense>
                <TextField
                  type="text"
                  name="last_name"
                  config={{
                    label: t('LAST_NAME'),
                    maxLength: 255,
                    InputProps: {
                      'data-testid': 'test_last_name',
                    },
                  }}
                />
              </FormMarginDense>
              <FormMarginDense>
                <DatePickerField
                  name="birth_date"
                  config={{
                    placeholder: 'DD / MM / YYYY',
                    label: t('DATE_OF_BIRTH'),
                    inputProps: {
                      'data-testid': 'test_dob',
                    },
                  }}
                />
              </FormMarginDense>
            </div>
            <div>
              {userType !== UserType.RENTAL_CARS ? (
                <FormMarginDense value="16">
                  <CheckboxTerm
                    onClick={() => {
                      setCheckMarketing((prev) => !prev);
                    }}
                    checked={checkMarketing}
                    text={t('KEEP_ME_UPDATED_NEW')}
                    testId="test_keep_updated"
                  />
                </FormMarginDense>
              ) : null}

              <FormMarginDense value={userType !== UserType.RENTAL_CARS ? '16' : '65'}>
                <CheckboxTerm
                  onClick={() => {
                    setCheckTerm((prev) => !prev);
                  }}
                  checked={checkTerm}
                  text={
                    <Trans
                      i18nKey="ACCEPT_TERM_AND_POLICY"
                      components={{
                        link1: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a
                            href="https://theout.zendesk.com/hc/en-gb/articles/9948882884125-Terms-and-Conditions"
                            target="blank"
                          />
                        ),
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        link2: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a
                            href="https://theout.zendesk.com/hc/en-gb/articles/9949036554141-Privacy-Policy"
                            target="blank"
                          />
                        ),
                      }}
                    />
                  }
                  testId="test_policy"
                />
              </FormMarginDense>
              <FormMarginDense>
                <Button
                  data-testid="test_create_account"
                  fullWidth
                  variant="contained"
                  color="white"
                  type="submit"
                  disabled={
                    !(
                      isValid &&
                      checkTerm &&
                      !errorLogin &&
                      values.email &&
                      values.password &&
                      values.first_name &&
                      values.last_name &&
                      values.birth_date
                    )
                  }
                >
                  {t('CREATE_ACCOUNT')}
                </Button>
              </FormMarginDense>
            </div>
          </StyledForm>
        )}
      </Formik>
      {/* <RecapchaContainer ref={reCapchaContainerElement}>
        <div id="recapcha-container" />
      </RecapchaContainer> */}
    </FormWrapper>
  );
};

export default RegisterPage;
