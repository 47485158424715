import DepositPaymentPage from '@/pages/DepositPayment';
import ErrorPage from '@/pages/Error';
import OutroPage from '@/pages/Outro';
import { RecapchaContainer } from '@/styles/common';
import { ModalWrapper } from '@/utils/modalProvider';
import { ModalRenderer } from '@/utils/modalUtils';
import React, { useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { IntroLayout } from '../container/IntroLayout';
import { KYCLayout } from '../container/KYCLayout';
import {
  AdditionDriverIntroPage,
  ConfirmAddressPage,
  ConfirmLicencePage,
  InsuranceDeclarationPage,
  Page404,
  PartnerPage,
  PassportUploadPage,
  RegisterPage,
  RentalCarPage,
  VerifyIdentity,
  VerifyPhoneNumberPage,
} from '../pages';

interface RootProps {}

let globalModalRef: any;

const RootContainer: React.FunctionComponent<RootProps> = () => {
  // capcha
  const reCapchaContainerElement = useRef(null);
  const clearRecapchaContent = () => {
    if (reCapchaContainerElement.current) {
      //@ts-ignore
      reCapchaContainerElement.current.innerHTML = '<div id="recapcha-container"></div>';
    }
  };
  useEffect(() => {
    ModalRenderer.registerModal(globalModalRef);
    clearRecapchaContent();
  }, []);

  const location = useLocation();
  useEffect(() => {
    ModalRenderer.close('all');
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/*" element={<IntroLayout />}>
          <Route path="" element={<PartnerPage />} />
          <Route path="*" element={<Page404 />} />
          <Route path="error" element={<ErrorPage />} />
          <Route path="additional-driver/:orderID" element={<AdditionDriverIntroPage />} />
          <Route path="rentalcars" element={<RentalCarPage />} />
          <Route path="thank-you" element={<OutroPage />} />
        </Route>
        <Route path="/*" element={<KYCLayout />}>
          <Route path="register" element={<RegisterPage />} />
          <Route path="verification-phone" element={<VerifyPhoneNumberPage />} />
          <Route path="verification-identity" element={<VerifyIdentity />} />
          <Route path="verification-identity/confirm-licence" element={<ConfirmLicencePage />} />
          <Route path="verification-identity/confirm-address" element={<ConfirmAddressPage />} />
          <Route path="verification-identity/upload-passport" element={<PassportUploadPage />} />
          <Route path="insurance-declaration" element={<InsuranceDeclarationPage />} />
          <Route path="deposit-payment" element={<DepositPaymentPage />} />
          <Route path="payment-method" element={<DepositPaymentPage />} />
        </Route>
      </Routes>
      <ModalWrapper ref={(ref) => (globalModalRef = ref)} />
      <RecapchaContainer ref={reCapchaContainerElement}>
        <div id="recapcha-container" />
      </RecapchaContainer>
    </>
  );
};

export default RootContainer;
