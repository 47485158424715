import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import { set } from 'lodash';

export default createSlice({
  name: 'user',
  initialState: {
    userInfo: null,
    orderId: null,
    userType: null,
    partner: null, // ony for concierge portal
    isAddPaymentMethod: null, // ony for concierge portal
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    clearUserInfo: (state) => {
      state.userInfo = null;
      state.userType = null;
      state.orderId = null;
      state.partner = null;
      state.isAddPaymentMethod = null;
    },
    updateUserType: (state, action) => {
      state.userType = action.payload;
    },
    updatePartner: (state, action) => {
      state.partner = action.payload;
    },
    updateOrderId: (state, action) => {
      state.orderId = action.payload;
    },
    updateIsAddPaymentMethod: (state, action) => {
      state.isAddPaymentMethod = action.payload;
    },
    updateUserLicence: (state, action) => {
      const newState = produce(state, (draft) => {
        draft = set(draft, 'userInfo.licence', action.payload);
      });
      return newState;
    },
    updateUserKYCConsent: (state) => {
      const newState = produce(state, (draft) => {
        draft = set(draft, 'userInfo.kyc_consent', true);
      });
      return newState;
    },
  },
});
