import { getCusInfoByToken } from '@/api/user';
import { LoadingPage } from '@/components/common';
import IntroPage from '@/components/page/Intro';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import errorReducer from '@/redux/reducers/errorReducer';
import userReducer from '@/redux/reducers/userReducer';
import { UserType } from '@/utils/constant';
import { saveInternalTokenAuth } from '@/utils/helper';
import { clearSavedInternalToken } from '@/utils/internalAuth';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const PartnerPage = () => {
  const [name, setName] = useState<string | undefined>('');
  const [partnerCode, setPartnerCode] = useState<string | undefined>('');
  const [isLoadingPartner, setLoadingPartner] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state: any) => state.loading);
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const { t } = useTranslation();

  const getCustomerNameAndCheckCredential = useCallback(async () => {
    dispatch({ type: 'SHOW_LOADING' });
    setLoadingPartner(true);
    await saveInternalTokenAuth();
    const customerInfo = await getCusInfoByToken(token);
    if (!customerInfo) {
      dispatch(errorReducer.actions.setError({ title: t('ERROR_LINK_TITLE'), content: t('ERROR_LINK_CONTENT') }));
      navigate('/error');
      return;
    }
    const { custom_token, email, first_name, partner_code } = customerInfo || {};
    setName(first_name);
    dispatch(userReducer.actions.setUserInfo({ email: email }));
    dispatch(userReducer.actions.setUserInfo({ customToken: custom_token }));
    setPartnerCode(partner_code);
    dispatch({ type: 'HIDE_LOADING' });
    setLoadingPartner(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!token) {
      // navigate('/rentalcars');
      return;
    }
    // update partner name for change logo
    getCustomerNameAndCheckCredential();
    dispatch(userReducer.actions.updatePartner(partnerCode));
    return () => {
      clearSavedInternalToken();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerCode]);

  return (
    <main>
      {token ? (
        <IntroPage userType={UserType.CONCIERGE_PORTAL} name={name} isLoadingPartner={isLoadingPartner} />
      ) : (
        <IntroPage userType={UserType.RENTAL_CARS} />
      )}
      {isLoading && <LoadingPage />}
    </main>
  );
};
export default PartnerPage;
