import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';

const BpIcon = styled('span')(({ theme }) => ({}));

const BpCheckedIcon = styled(CheckIcon, {
  shouldForwardProp: (propName: string) => propName !== 'size',
})<{ width: Record<string, string> | string }>(({ theme, size }) => ({
  ...(size === 'small' && {
    width: '16px',
    height: '16px',
    padding: '2px',
  }),
  ...(size === 'medium' && {
    width: '20px',
    height: '20px',
    padding: '3px',
  }),
  borderRadius: '50%',
  color: '#000',
  backgroundColor: '#22c55e',
  border: 'none !important',
}));

const BaseCheckbox = styled(Checkbox, {
  shouldForwardProp: (propName: string) => propName !== 'size' && propName !== 'disabled',
})<{ width: Record<string, string> | string; disabled: boolean }>(({ theme, size, disabled }) => ({
  border: '1px solid #fff',
  borderRadius: '50%',
  padding: '0px',
  ...(size === 'small' && {
    width: '16px',
    height: '16px',
  }),
  ...(size === 'medium' && {
    width: '20px',
    height: '20px',
  }),
  ...(disabled && {
    opacity: '0.2',
  }),
  cursor: 'auto',
}));

export default function CircleCheckbox(props: any) {
  const { size = 'medium', disabled = false, tabIndex } = props;
  return (
    <BaseCheckbox
      disabled={disabled}
      disableRipple
      size={size}
      checkedIcon={<BpCheckedIcon size={size} />}
      icon={<BpIcon size={size} />}
      {...props}
      inputProps={{
        tabIndex: tabIndex,
      }}
      sx={{
        '&.Mui-checked': {
          border: 'none',
        },
      }}
    />
  );
}
